import {Activity, Appointment} from "@/types";

export enum EventColors {
    CONSULTING = '#9BCDF1',
    CALLING = '#FFDAD5'
}

export enum ActivityStatus {
    DONE = "#B7E1CD",
    PENDING = "#EFEFEF"
}

export enum LocalStorageKeys {
    selectedPerson = 'selectedPerson'
}

export const defaultActivity: Activity = {
    id: 0,
    personId: 0,
    date: '2024-10-01',
    duration: 0,
    projectId: 0,
    type: 'Calling',
    notes: "",
    isDone: false,
    incomePerHour: 0,
    incomePerAppointment: 0,
};

export const defaultAppointment: Appointment = {
    id: 0,
    appointmentWhen: '2024-10-01',
    description: '',
    bookedByPersonId: 0,
    cancellationDescription: '',
    status: 'Booked',
    activityId: 0,
    projectId: 0,
    income: 0
};

export enum AppColors {
    Sand = "#F5EEE6",
    Lila = "#8B3DFF",
}

