'use client';
import React, {createContext, useState, useContext, ReactNode, useEffect} from 'react';
import {startOfMonth, startOfWeek} from "date-fns";
import {useSearchParams} from "next/navigation";
import {CalendarPerson, CalendarProject} from "@/types";
import {deleteCookie, setCookie} from "cookies-next";

interface AppContextProps {
    firstDayOfWeek: Date;
    setFirstDayOfWeek: (firstDay: Date) => void;
    allocationMonth: Date;
    setAllocationMonth: (month: Date) => void;
    selectedPersonId?: number;
    updateSelectedPersonId: (personId: number) => void;
    persons: CalendarPerson[];
    projects: Record<number, CalendarProject>;
    
}

const AppContext = createContext<AppContextProps | undefined>(undefined);
AppContext.displayName = 'AppContext';


interface AppProviderProps {
    children: ReactNode;
    persons: CalendarPerson[];
    projects: Record<number, CalendarProject>;
    selectedPersonId?: number;
}

export const AppProvider: React.FC<AppProviderProps> = (props) => {
    const {children, persons, projects, selectedPersonId : initialSelectedPersonId} = props;
    const [allocationMonth, setAllocationMonth] = useState<Date>(startOfMonth(new Date()));
    const [firstDayOfWeek, setFirstDayOfWeek] = useState<Date>(startOfWeek(new Date(), {weekStartsOn: 1}));
    const [selectedPersonId, setSelectedPersonId] = useState<number>(initialSelectedPersonId || 0);
    

    const params = useSearchParams();
    useEffect(() => {
        const allocationMonthParam = params.get('allocationMonth');
        if (allocationMonthParam) {
            setAllocationMonth(startOfMonth(new Date(allocationMonthParam)));
        }
    }, [params]);
    
    
    const updateSelectedPersonId = (personId: number) => {
        if (personId && personId > 0) {
            setCookie('selectedPerson', personId.toString());
        } else {
            deleteCookie('selectedPerson');
        }
        setSelectedPersonId(personId);
    };

    return (
        <AppContext.Provider value={{
            firstDayOfWeek,
            setFirstDayOfWeek,
            allocationMonth,
            setAllocationMonth,
            selectedPersonId,
            updateSelectedPersonId,
            persons,
            projects
        }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = (): AppContextProps => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};