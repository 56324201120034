import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/DateFormatContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/app/src/context/AppContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ThemeRegistry.tsx");
