import {createTheme} from '@mui/material/styles';
import {AppColors} from "@/config/constants";

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
    },
    typography: {
        h1: {
            color: '#140031',
        },
        h2: {
            color: '#140031',
        },
        h3: {
            color: '#140031',
        },
        h4: {
            color: '#140031',
        },
        h5: {
            color: '#140031',
        },
        h6: {
            color: '#140031',
        },
    },
    components: {
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: "white",
                        backgroundColor: AppColors.Lila,
                        '&:hover': {
                            backgroundColor: '#9A4DFF',
                        },
                    },
                },
            },
        },
    },
});

export default theme;