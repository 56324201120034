'use client';
import {setDefaultOptions} from "date-fns";
import {sv} from "date-fns/locale";
import {ReactNode, useEffect} from "react";

const DateFormatContext = ({children}: {children: ReactNode}) => {
    useEffect(() => {
        setDefaultOptions({locale: sv})
    }, [])
    return children;
}

export default DateFormatContext;